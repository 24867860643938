<template>
  <div class="box box-primary">
    <div class="box-header with-border">
        <span class="fa-stack new-account-icon"><i class="fa fa-envelope"></i><i class="fa fa-bullhorn icon-plus"></i></span>
        <h3 class="box-title">Campaign Messages </h3>
    </div>
    <div class="box-body">
      <div class="mbottom-15">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="text-capitalize campign-col">Waiting Period Before Sending (In Days)</th>
                        <th class="text-capitalize">Message To Send</th>
                        <th class="text-capitalize">Mailbox To Send From</th>
                        <th class="campign-col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-capitalize">0</td>
                        <td class="text-capitalize">iService 8 - Feedback Announcement</td>
                        <td class="text-capitalize">One-to-One Service Sales</td>
                        <td class="icon-col no-sort" width="30">
                            <div><button class="icon-button btn btn-xs btn-link mright-5" type="button"><i class="fa fa-pencil"></i></button><button class="icon-button btn btn-xs btn-link button-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td class="text-capitalize">1</td>
                        <td class="text-capitalize">iService 8 - Search Contacts</td>
                        <td class="text-capitalize">One-to-One Service Sales</td>
                        <td class="icon-col no-sort" width="30">
                            <div><button class="icon-button btn btn-xs btn-link mright-5" type="button"><i class="fa fa-pencil"></i></button><button class="icon-button btn btn-xs btn-link button-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td class="text-capitalize">14</td>
                        <td class="text-capitalize">iService 8 - Search Contacts</td>
                        <td class="text-capitalize">One-to-One Service Sales</td>
                        <td class="icon-col no-sort" width="30">
                            <div><button class="icon-button btn btn-xs btn-link mright-5" type="button"><i class="fa fa-pencil"></i></button><button class="icon-button btn btn-xs btn-link button-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td class="text-capitalize">28</td>
                        <td class="text-capitalize">iService 8 - Contact Summary</td>
                        <td class="text-capitalize">One-to-One Service Sales</td>
                        <td class="icon-col no-sort" width="30">
                            <div><button class="icon-button btn btn-xs btn-link mright-5" type="button"><i class="fa fa-pencil"></i></button><button class="icon-button btn btn-xs btn-link button-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td class="text-capitalize">35</td>
                        <td class="text-capitalize">iService 8 - Contact History</td>
                        <td class="text-capitalize">One-to-One Service Sales</td>
                        <td class="icon-col no-sort" width="30">
                            <div><button class="icon-button btn btn-xs btn-link mright-5" type="button"><i class="fa fa-pencil"></i></button><button class="icon-button btn btn-xs btn-link button-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                        </td>
                    </tr>
                </tbody>
                </table>

      </div>

      <div class="panel panel-default col-12 bg-gray-light">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="inputPassword3" class="col-md-12 control-label text-left">Day Offset</label>
                    <div class="col-12"><input type="number" step="1" class="form-control"></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="inputPassword3" class="col-md-12 control-label text-left">Message</label>
                    <div class="col-12">
                        <Multiselect
                            v-model="value"
                            placeholder="-Select Message-"
                            label="name"
                            :searchable="true"
                            :options="[
                                { value: 'Message 1', name: 'Message 1',level:'1'},
                                { value: 'Message 2', name: 'Message 2',level:'1'}
                            ]"
                            >
                            <template v-slot:option="{ option }">
                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="inputPassword3" class="col-md-12 control-label text-left">Mailbox</label>
                    <div class="col-12">
                       <Multiselect
                        v-model="value"
                        placeholder="-Select Mailbox-"
                        label="name"
                        :searchable="true"
                        :options="[
                            { value: 'Jhon Doe', name: 'Jhone Doe(Sales)',level:'1'}
                        ]"
                        >
                        <template v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                        </template>
                    </Multiselect>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="campaign-btn"><button class="btn btn-primary btn-sm" type="button">Add</button><button class="btn btn-link btn-sm" type="button">Cancel</button></div>
            </div>
        </div>
        </div>

      <div class="row" v-if="addMessage"><button class="btn btn-lg btn-link" type="button"><i class="fa fa-plus-circle text-light-blue"></i> Add another message to campaign </button></div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Campaign Message',
  components: {
   
  },
   setup(){      
       // var showNew =   reactive(false); 
  }
}
</script>